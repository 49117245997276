import React from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'

import AnimalLookup from 'components/AnimalLookup'
import SearchOne from '../SearchOne'

import styles from './styles'

const SearchContainer = ({ onClose, q, showSearchBar }) => {
  return showSearchBar ? (
    <CSSTransition
      appear
      classNames={{
        appear: styles.appear,
        appearActive: styles.appearActive,
      }}
      in
      timeout={120}
    >
      <div className={styles.flexWrapper}>
        <SearchOne q={q} onClose={onClose} />
        <AnimalLookup onClose={onClose} />
        <a className={styles.closeLink} onClick={onClose}>
          <svg className={styles.closeIcon}>
            <use xlinkHref='/images/sprites.svg#cross' />
          </svg>
          Close
        </a>
      </div>
    </CSSTransition>
  ) : null
}

SearchContainer.propTypes = {
  onClose: PropTypes.func,
  q: PropTypes.string,
  showSearchBar: PropTypes.bool.isRequired,
}

export default SearchContainer
