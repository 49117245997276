import React from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { getRequestStatus, SUCCESS } from 'lib/selectors/requestStateSelectors'

const ProtectedRoute = ({
  component: Component,
  isLoggedIn,
  loggedInRequestStatus,
  ...rest
}) => {
  const redirectToAuth = (location, match) => {
    let currentQueryParams = queryString.parse(location.search)
    let currentPath = location.pathname

    // If the path contains an :id, double encode it so that it survives the UI Proxy param decoding
    if (Object.keys(match.params).length > 0) {
      Object.values(match.params).forEach(value => {
        if (value.length > 0)
          currentPath = currentPath.replace(value, encodeURIComponent(value))
      })
    }

    let params = queryString.stringify({
      ...currentQueryParams,
      path: currentPath,
    })

    const loginUrl = global.config.OAUTH_REDIRECT_TO_LOCAL
      ? `${global.config.UI_PROXY_ENDPOINT}/sample_management/local/connect/identity?${params}`
      : `${global.config.UI_PROXY_ENDPOINT}/sample_management/connect/identity?${params}`

    window.location.assign(loginUrl)
  }

  const renderRoute = props => {
    if (loggedInRequestStatus && loggedInRequestStatus === SUCCESS) {
      if (isLoggedIn) return <Component {...props} />
      else redirectToAuth(props.location, props.match)
    }
    // We render nothing until we know that the user's loggedIn status has been fetched
    return null
  }

  return <Route {...rest} render={renderRoute} />
}

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
}

export { ProtectedRoute }
export default connect(store => {
  return {
    isLoggedIn: store.loggedIn.data.isLoggedIn,
    loggedInRequestStatus: getRequestStatus(store.loggedIn.requestState),
  }
})(ProtectedRoute)
