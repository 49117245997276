import axios from 'lib/axios'

function success({ baseType, dispatch, response, extractPayload, nextAction }) {
  let payload = extractPayload ? extractPayload(response) : response.data
  dispatch({ type: `${baseType}_FULFILLED`, payload })
  if (nextAction) dispatch(nextAction(response))
  return response
}

function failure({ baseType, dispatch, error }) {
  dispatch({ type: `${baseType}_REJECTED`, payload: error })
  throw error
}

function get({ baseType, url, nextAction, extractPayload = null }) {
  return function(dispatch) {
    dispatch({ type: baseType })
    return axios
      .get(url)
      .then(response => {
        return success({
          baseType,
          dispatch,
          response,
          nextAction,
          extractPayload,
        })
      })
      .catch(error => {
        return failure({ baseType, dispatch, error })
      })
  }
}

function patch({ baseType, url, body, nextAction, extractPayload = null }) {
  return function(dispatch) {
    dispatch({ type: baseType })
    return axios
      .patch(url, body)
      .then(response => {
        return success({
          baseType,
          dispatch,
          response,
          nextAction,
          extractPayload,
        })
      })
      .catch(error => {
        return failure({ baseType, dispatch, error })
      })
  }
}

function put({ baseType, url, body, nextAction, extractPayload = null }) {
  return function(dispatch) {
    dispatch({ type: baseType })
    return axios
      .put(url, body)
      .then(response => {
        return success({
          baseType,
          dispatch,
          response,
          nextAction,
          extractPayload,
        })
      })
      .catch(error => {
        return failure({ baseType, dispatch, error })
      })
  }
}

function post({ baseType, url, body, nextAction, extractPayload = null }) {
  return function(dispatch) {
    dispatch({ type: baseType })
    return axios
      .post(url, body)
      .then(response => {
        return success({
          baseType,
          dispatch,
          response,
          nextAction,
          extractPayload,
        })
      })
      .catch(error => {
        return failure({ baseType, dispatch, error })
      })
  }
}

function destroy({ baseType, url, body, nextAction }) {
  return function(dispatch) {
    dispatch({ type: baseType })
    return axios
      .delete(url, body)
      .then(response => {
        return success({ baseType, dispatch, response, nextAction })
      })
      .catch(error => {
        return failure({ baseType, dispatch, error })
      })
  }
}

export { get, patch, put, post, destroy }
