import * as Sentry from '@sentry/browser'
import { name, version } from '../package.json'

//TODO figure out where to store this - for now it's ok to hardcode, we can generate new credentials as required
const SENTRY_DSN = 'https://f64e48c6704d4488a3467013298d5dce@sentry.io/2691682'

function initialise(config) {
  let { ENVIRONMENT_NAME, IS_RUNNING_LOCALLY } = config
  //TODO we can probably remove the IS_RUNNING_LOCALLY var/check once we're sourcing the SENTRY_DSN externally. Presence of the SENTRY_DSN would then be enough.
  let isRunningLocally = IS_RUNNING_LOCALLY && IS_RUNNING_LOCALLY === 'yes'

  if (!isRunningLocally && SENTRY_DSN) {
    Sentry.init({
      // NOTE: As per Sentry's source mapping docs: 'The release name must be unique within your organization'
      release: `${name}-${version}`,
      dsn: SENTRY_DSN,
      environment: ENVIRONMENT_NAME,
    })
  }
}

export default initialise
