import { createSelector } from 'reselect'

const getCustomer = createSelector(
  [data => data],
  data => {
    return data ? data.customer.operation.data : null
  }
)

const getAddresses = createSelector(
  [data => data],
  data => {
    return data ? data.customer.operation.addresses.data : null
  }
)

const getCreditValidity = createSelector(
  [data => data],
  data => {
    return data ? data.customer.operation.creditValidity.data.credit_validity : null
  }
)

const getContactNumbers = createSelector(
  [data => data],
  data => {
    return data ? data.customer.operation.contactNumbers.data : null
  }
)

export { getCustomer, getAddresses, getCreditValidity, getContactNumbers }
