import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import AnimalLookupForm from './AnimalLookupForm'
import Button from 'lib/components/Button'

import styles from './styles'

const AnimalLookup = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(false)

  const containerRef = useRef()

  const handleDocumentClick = useCallback(e => {
    if (containerRef && containerRef.current) {
      if (containerRef.current.contains(e.target)) e.stopPropagation()
      else setIsOpen(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)
    return () => document.removeEventListener('click', handleDocumentClick)
    // TODO: is this necessary/can we remove?
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleToggle() {
    setIsOpen(!isOpen)
  }

  let svgId = isOpen ? 'up' : 'down'

  return (
    <div ref={containerRef} className={styles.animalLookupContainer}>
      <Button className='buttonPrimary' onClick={handleToggle}>
        Animal lookup
        <svg className={styles.dropdownIcon}>
          <title id={`svg-id-caret-${svgId}`}>Caret {svgId} SVG</title>
          <use xlinkHref={`/images/sprites.svg#caret-${svgId}`} />
        </svg>
      </Button>
      <AnimalLookupForm isOpen={isOpen} onClose={onClose} />
    </div>
  )
}

AnimalLookup.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default AnimalLookup
