import { get, post } from 'lib/reduxHelpers/thunks'
import { href } from '@licnz/js-utils'
import { proxyUrl } from 'lib/utils/request'
import { customerTypes } from '../types'

function fetchCustomer(operationLink) {
  return function(dispatch) {
    dispatch(fetchOperation(operationLink)).then(({ data }) => {
      dispatch(fetchOperationAddresses(data.party))
      dispatch(fetchOperationContactNumbers(data.party))
      dispatch(fetchBillingEntities(data.party)).then(response => {
        dispatch(fetchOperationCreditValidity(response.data.items))
      })
    })
  }
}

function fetchOperation(operationLink) {
  let baseType = customerTypes.FETCH_OPERATION
  let url = proxyUrl({ link: operationLink })

  return get({ baseType, url, extractPayload: response => response.data.party })
}

function fetchOperationAddresses(operation) {
  let operationAddressesLink = href({
    links: operation.links,
    rel: 'addresses',
  })
  let url = proxyUrl({ link: operationAddressesLink })
  let baseType = customerTypes.FETCH_OPERATION_ADDRESSES

  return get({
    url,
    baseType,
    extractPayload: response => response.data.addresses,
  })
}

function fetchOperationContactNumbers(operation) {
  let operationContactNumbersLink = href({
    links: operation.links,
    rel: 'contact_numbers',
  })
  let url = proxyUrl({ link: operationContactNumbersLink })
  let baseType = customerTypes.FETCH_OPERATION_CONTACT_NUMBERS

  return get({
    url,
    baseType,
    extractPayload: response => response.data.contact_numbers,
  })
}

function fetchBillingEntities(operation) {
  let operationPartyLink = href({
    links: operation.links,
    rel: 'up',
  })

  let url = proxyUrl({
    link: `${global.config.ACCOUNT_SERVICE_ENDPOINT}/billing_entities/search`,
  })
  let baseType = customerTypes.FETCH_BILLING_ENTITIES
  let body = { directives: '_asc', party_identity: operationPartyLink }

  return post({
    baseType,
    url,
    body,
    extractPayload: response => response.data.items,
  })
}

function fetchOperationCreditValidity(billingEntities) {
  let billingEntityId = billingEntities.length ? billingEntities[0].id : null

  let baseType = customerTypes.FETCH_CREDIT_VALIDITY
  let url = proxyUrl({
    link: `${global.config.ACCOUNT_SERVICE_ENDPOINT}/billing_entities/${billingEntityId}/credit_validity`,
  })

  let body = {
    _type: 'credit_validity',
    payment_method_symbol: 'http://purl.org/goodrelations/v1#ByInvoice',
  }

  return post({
    baseType,
    url,
    body,
  })
}

function clearCustomer() {
  return function(dispatch) {
    dispatch({ type: customerTypes.CLEAR_FETCH_OPERATION_ADDRESSES })
    dispatch({ type: customerTypes.CLEAR_FETCH_OPERATION_CONTACT_NUMBERS })
    dispatch({ type: customerTypes.CLEAR_FETCH_BILLING_ENTITIES })
    dispatch({ type: customerTypes.CLEAR_FETCH_CREDIT_VALIDITY })
    dispatch({ type: customerTypes.CLEAR_FETCH_OPERATION })
  }
}

export {
  fetchCustomer,
  fetchOperation,
  fetchOperationAddresses,
  fetchOperationContactNumbers,
  fetchBillingEntities,
  fetchOperationCreditValidity,
  clearCustomer,
}
