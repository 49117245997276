import React from 'react'
import PropTypes from 'prop-types'

import RequestWrapper, { IN_PROGRESS } from '@licnz/react-request-wrapper'

import styles from './styles'

const Button = ({
  children,
  className,
  disabled,
  loading,
  onClick,
  type,
  requestState,
  inProgressText,
}) => {
  const setClasses = () => {
    return className
      .split(' ')
      .map(buttonClass => styles[buttonClass])
      .join(' ')
  }

  return (
    <button
      type={type || 'button'}
      className={className && setClasses()}
      onClick={onClick}
      disabled={disabled}
    >
      <RequestWrapper
        loading={loading}
        requestState={requestState}
        color='#FFFFFF'
        size={2}
      >
        {children}
      </RequestWrapper>
      {(requestState === IN_PROGRESS || loading) && inProgressText}
    </button>
  )
}

Button.defaultProps = {
  inProgressText: null,
}

Button.propTypes = {
  type: PropTypes.string,
  className: (props, propName, componentName) => {
    let error
    const prop = props[propName]
    if (typeof prop !== 'string')
      error = new Error(
        `'${componentName}' expected className of type 'string'. Received ${prop}`
      )
    prop.split(' ').forEach(token => {
      const validClasses = [
        'buttonPrimary',
        'buttonSecondary',
        'buttonTertiary',
        'buttonOutline',
        'buttonInverted',
        'buttonOutlineInverted',
        'buttonError',
        'buttonSmall',
        'buttonMedium',
        'buttonLarge',
        'buttonBlock',
        'buttonSearch',
        'buttonLoadText',
      ]
      if (!validClasses.includes(token)) {
        error = new Error(
          `'${componentName}' expects all classNames to be one of ${validClasses}. Received ${token}`
        )
      }
    })
    return error
  },
  onClick: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  inProgressText: PropTypes.string,
  loading: PropTypes.bool,
}

export default Button
