import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles'

const ButtonGroup = ({ children }) => {
  return <div className={styles.buttonGroup}>{children}</div>
}

ButtonGroup.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ButtonGroup
