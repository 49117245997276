import { useDispatch, useSelector } from 'react-redux'
import { href } from '@licnz/js-utils'

import {
  fetchCustomer,
  getCustomer,
  getContactNumbers,
  getAddresses,
  getCreditValidity,
  clearCustomer,
} from 'lib/components/ReduxCustomer'

function useCustomer() {
  const dispatch = useDispatch()
  /***  Get data from redux state ***/
  const customer = useSelector(state => getCustomer(state))
  const creditValidity = useSelector(state => getCreditValidity(state))
  const contactNumbers = useSelector(state => getContactNumbers(state))
  const addresses = useSelector(state => getAddresses(state))
  const requestState = useSelector(state => state.customer.operation.requestState)

  function getCustomerData(partyLink) {
    let linkFromState =
      customer &&
      href({
        links: customer.links,
        rel: 'self',
      })
    if (!linkFromState || linkFromState !== partyLink) {
      dispatch(fetchCustomer(partyLink))
    }
  }

  function clearCustomerData(currentLink) {
    let customerLink =
      customer &&
      href({
        links: customer.links,
        rel: 'self',
      })

    if (customerLink && customerLink !== currentLink) {
      dispatch(clearCustomer())
    }
  }

  let data = {
    customer,
    creditValidity,
    contactNumbers,
    addresses,
  }

  let loading = requestState ? requestState.fetching : null
  let error = requestState ? requestState.errors : null

  return {
    getCustomerData,
    data,
    loading,
    error,
    clearCustomerData,
  }
}

export { useCustomer }
