import { Component } from 'react'
import { connect } from 'react-redux'

import { fetchIdToken } from 'actions/idTokenActions'
import { fetchLoggedIn } from 'lib/actions/loggedInActions'
import { getRequestStatus, SUCCESS } from 'lib/selectors/requestStateSelectors'
import { renderError } from '@licnz/react-toast-notifications'

class AuthHandler extends Component {
  componentDidMount() {
    let { fetchLoggedIn } = this.props
    fetchLoggedIn().catch(err =>
      renderError({ message: 'We were unable to check your logged in status' })
    )
  }

  componentDidUpdate() {
    let { fetchIdToken, idToken, idTokenRequestStatus, isLoggedIn } = this.props

    if (isLoggedIn && !idToken && !idTokenRequestStatus) {
      fetchIdToken().catch(err => {
        renderError({
          message: 'We were unable to fetch your authentication token',
          detail: err,
        })
      })
    }
  }

  needsToLogIn() {
    return this.props.isLoggedInRequestStatus === SUCCESS && !this.props.isLoggedIn
  }

  sendToLogIn() {
    let loginUrl = global.config.OAUTH_REDIRECT_TO_LOCAL
      ? `${global.config.UI_PROXY_ENDPOINT}/sample_management/local/connect/identity`
      : `${global.config.UI_PROXY_ENDPOINT}/sample_management/connect/identity`
    window.location.assign(loginUrl)
  }

  render() {
    return this.needsToLogIn() ? this.sendToLogIn() : null
  }
}

const mapDispatchToProps = {
  fetchIdToken,
  fetchLoggedIn,
}

export { AuthHandler }
export default connect(
  state => {
    return {
      idToken: state.idToken.data,
      idTokenRequestStatus: getRequestStatus(state.idToken.requestState),
      isLoggedIn: state.loggedIn.data.isLoggedIn,
      isLoggedInRequestStatus: getRequestStatus(state.loggedIn.requestState),
    }
  },
  mapDispatchToProps
)(AuthHandler)
