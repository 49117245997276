function typesForBaseType(baseType) {
  return {
    [baseType]: baseType,
    [`${baseType}_FULFILLED`]: `${baseType}_FULFILLED`,
    [`${baseType}_REJECTED`]: `${baseType}_REJECTED`,
    [`CLEAR_${baseType}`]: `CLEAR_${baseType}`,
  }
}

const idTokenTypes = typesForBaseType('FETCH_ID_TOKEN')

const profileTypes = {
  FETCH_PROFILE: 'FETCH_PROFILE',
  FETCH_PROFILE_FULFILLED: 'FETCH_PROFILE_FULFILLED',
  FETCH_PROFILE_REJECTED: 'FETCH_PROFILE_REJECTED',
  CLEAR_FETCH_PROFILE: 'CLEAR_FETCH_PROFILE',
}

export { idTokenTypes, profileTypes }
