import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles'

const FormRow = ({
  children,
  errorDetail,
  helpText,
  inline,
  label,
  showError,
  smallLabel,
}) => {
  let formRowClassNames = `${styles.formRow} ${inline && styles.formRowInline}`
  let labelClassNames = smallLabel ? styles.smallLabel : styles.label

  return (
    <div className={formRowClassNames}>
      {label && (
        <div className={styles.labelWrapper}>
          <label className={labelClassNames}>{label}</label>
          {helpText && <small className={styles.helpText}>{helpText}</small>}
          {showError && <small className={styles.error}>{errorDetail}</small>}
        </div>
      )}
      {children}
    </div>
  )
}

FormRow.propTypes = {
  children: PropTypes.node,
  errorDetail: PropTypes.string,
  helpText: PropTypes.string,
  label: PropTypes.string,
  showError: PropTypes.bool,
  smallLabel: PropTypes.bool,
}

export default FormRow
