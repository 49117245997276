import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import urlPropType from 'url-prop-type'

import styles from './styles'

function AppNav({ navLinks }) {
  return (
    <ul className={styles.navList}>
      {navLinks.map(item => (
        <li className={styles.navListItem} key={item.id}>
          <a
            className={classnames(styles.navLink, { [styles.active]: item.isActiveApp })}
            href={item.url}
          >
            {item.label}
          </a>
        </li>
      ))}
    </ul>
  )
}

AppNav.propTypes = {
  navLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: urlPropType.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default AppNav
