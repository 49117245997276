import axios from 'lib/axios'

import { profileTypes } from 'actions/types'

function fetchProfile() {
  return function(dispatch) {
    dispatch({ type: profileTypes.FETCH_PROFILE })
    return axios
      .get(`${global.config.UI_PROXY_ENDPOINT}/api/profile/for_current_subject`)
      .then(response => {
        dispatch({
          type: profileTypes.FETCH_PROFILE_FULFILLED,
          payload: response.data,
        })
      })
      .catch(err => {
        dispatch({ type: profileTypes.FETCH_PROFILE_REJECTED, payload: err })
        throw err
      })
  }
}

export { fetchProfile }
