import { combineReducers } from 'redux'

import { customerReducer as customer } from 'lib/components/ReduxCustomer'
import idToken from 'reducers/idTokenReducer'
import loggedIn from 'lib/reducers/loggedInReducer'
import profile from 'reducers/profileReducer'

export default combineReducers({
  customer,
  idToken,
  loggedIn,
  profile,
})
