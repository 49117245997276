import axios from 'lib/axios'
import { loggedInTypes } from 'lib/actions/types'

export function fetchLoggedIn() {
  return function(dispatch) {
    dispatch({ type: loggedInTypes.FETCH_LOGGED_IN })

    return axios
      .get(`${global.config.UI_PROXY_ENDPOINT}/api/logged_in`)
      .then(response => {
        dispatch({
          type: loggedInTypes.FETCH_LOGGED_IN_FULFILLED,
          payload: response.data,
        })
      })
      .catch(err => {
        dispatch({ type: loggedInTypes.FETCH_LOGGED_IN_REJECTED, payload: err })
        throw err
      })
  }
}
