import React from 'react'
import { connect } from 'react-redux'
import { ApolloProvider } from '@apollo/react-hooks'
import { apolloClient } from 'lib/utils/apolloClient'

const apolloClients = {
  baseClient: null,
  labFulfillmentClient: null,
}

const getApolloClient = () => {
  return apolloClients
}

const setupClients = idToken => {
  apolloClients.baseClient = apolloClient(
    `${global.config.SAMPLE_MANAGEMENT_SERVICE_ENDPOINT}/api`,
    idToken
  )
  apolloClients.labFulfillmentClient = apolloClient(
    `${global.config.LAB_FULFILLMENT_SERVICE_ENDPOINT}/api`,
    idToken
  )

  return apolloClients
}

const ApolloClientWrapper = ({ children, idToken }) => {
  let { baseClient } = setupClients(idToken)

  return baseClient ? (
    <ApolloProvider client={baseClient}>{children}</ApolloProvider>
  ) : null
}

export { ApolloClientWrapper, getApolloClient }
export default connect(state => {
  return {
    idToken: state.idToken.data,
  }
})(ApolloClientWrapper)
