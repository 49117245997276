import React, { Component } from 'react'
import * as Sentry from '@sentry/browser'
import PropTypes from 'prop-types'

import ButtonGroup from 'lib/components/ButtonGroup/ButtonGroup'
import Button from 'lib/components/Button/Button'
import SectionHeading from 'lib/components/headings/SectionHeading'
import { Gutters, Main, PaddedBlock, Section } from '@licnz/react-layout'

/**
 * This error boundary provides functionality for the user to report feedback or retry
 * loading the UI that errored.
 */
class SentryErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { eventId: null }

    this.handleReportFeedback = this.handleReportFeedback.bind(this)
    this.handleRetry = this.handleRetry.bind(this)
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  handleReportFeedback() {
    Sentry.showReportDialog({ eventId: this.state.eventId })
  }

  handleRetry() {
    this.setState({ hasError: false })
  }

  render() {
    if (this.state.hasError) {
      //TODO design and build flow for reporting bugs and decide how best to integrate with the UI
      return (
        <Main>
          <Gutters>
            <Section spaced>
              <PaddedBlock>
                <SectionHeading title='Oops!' />
                <p>Something went horribly wrong</p>
                <ButtonGroup>
                  <Button className='buttonPrimary' onClick={this.handleReportFeedback}>
                    Report feedback
                  </Button>
                  <Button className='buttonSecondary' onClick={this.handleRetry}>
                    Retry
                  </Button>
                </ButtonGroup>
              </PaddedBlock>
            </Section>
          </Gutters>
        </Main>
      )
    }

    return this.props.children
  }
}

SentryErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SentryErrorBoundary
