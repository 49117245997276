import { applyMiddleware, createStore, compose } from 'redux'

import thunk from 'redux-thunk'

import reducer from 'reducers/index'

const middleware = applyMiddleware(thunk)
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const initialState = {} //TODO: put initialised schema here

export default (state = initialState) =>
  createStore(reducer, state, composeEnhancers(middleware))
