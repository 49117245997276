import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles'

const SearchTrigger = ({ handleOpen }) => (
  <div className={styles.menuSearch}>
    <a className={styles.searchLink} onClick={handleOpen}>
      <svg className={styles.searchIcon}>
        <use xlinkHref='/images/sprites.svg#search' />
      </svg>
      Search
    </a>
  </div>
)

SearchTrigger.propTypes = {
  handleOpen: PropTypes.func.isRequired,
}

export default SearchTrigger
