import { combineReducers } from 'redux'
import createReducer from 'lib/reducers/createReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import { profileTypes } from 'actions/types'

const INITIAL_STATE = null

function dataHandler(state, action) {
  return action.payload || INITIAL_STATE
}

const dataReducer = createReducer(INITIAL_STATE, {
  [profileTypes.FETCH_PROFILE_FULFILLED]: dataHandler,
  [profileTypes.CLEAR_PROFILE]: dataHandler,
})

const reducer = combineReducers({
  requestState: requestStateReducer(profileTypes.FETCH_PROFILE),
  data: dataReducer,
})

export default reducer
