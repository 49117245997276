import gql from 'graphql-tag'

export const SEARCH_ONE = gql`
  query searchOne($text: String!) {
    searchOne(text: $text) {
      __typename
      ... on Party {
        kind
        handle
        identifier {
          identifier
        }
        links {
          rel
          href
        }
      }
      ... on WorkOrder {
        identifier {
          identifier
        }
        workOrderLink
      }
      ... on Reservation {
        identifier
        workOrder {
          identifier {
            identifier
          }
          workOrderLink
        }
      }
    }
  }
`
