import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'

import Button from 'lib/components/Button'
import FormRow from 'lib/components/FormRow'
import { Form, Formik, Field } from 'formik'
import { PaddedBlock } from '@licnz/react-layout'

import styles from './styles'

const SEARCH_PARAMS_MAPPING = {
  birthId: 'Birth ID',
  animalKey: 'Animal key',
  ahbNumber: 'AHB number',
  bullCode: 'Bull code',
  participantCode: 'PTPT',
  managementNumber: 'Management number',
  sampleId: 'Sample ID',
}

const INITIAL_VALUES = {
  birthId: '',
  animalKey: '',
  ahbNumber: '',
  bullCode: '',
  participantCode: '',
  managementNumber: '',
  sampleId: '',
}

const AnimalLookupForm = ({ isOpen, onClose }) => {
  function handleSubmit(values) {
    let nonNullParams = { ...values }
    Object.keys(values).forEach(key => values[key] === '' && delete nonNullParams[key])
    let stringifiedParams = queryString.stringify(nonNullParams)

    window.open(
      `${global.config.DIAGNOSTICS_FRONTEND_ENDPOINT}/#/animal_search?${stringifiedParams}`
    )

    onClose()
  }

  const FormRowWithFormField = ({
    disabled,
    field: { name, onChange, value },
    onReset,
  }) => {
    return (
      <FormRow>
        <label className={styles.label}>
          <span htmlFor={name}>{SEARCH_PARAMS_MAPPING[name]}</span>
          <input
            className={styles.input}
            disabled={disabled}
            name={name}
            onChange={onChange}
            type='text'
            value={value}
          />
          <ResetButton name={name} onReset={onReset} value={value} />
        </label>
      </FormRow>
    )
  }

  const InlineFormField = ({ disabled, field: { name, onChange, value }, onReset }) => {
    return (
      <>
        <label className={styles.label}>
          <span htmlFor={name}>{SEARCH_PARAMS_MAPPING[name]}</span>
          <input
            className={styles.inlineInput}
            disabled={disabled}
            name={name}
            onChange={onChange}
            type='text'
            value={value}
          />
          <ResetButton name={name} onReset={onReset} value={value} />
        </label>
      </>
    )
  }

  const ResetButton = ({ name, onReset, value }) => {
    return (
      <button
        className={classnames(styles.resetIcon, {
          [styles.hideResetIcon]: value === '',
        })}
        onClick={onReset}
        tabIndex='-1'
        type='reset'
      >
        <span className={styles.a11y}>Reset {SEARCH_PARAMS_MAPPING[name]}</span>
      </button>
    )
  }

  return isOpen ? (
    <div className={styles.formWrapper}>
      <PaddedBlock>
        <Formik initialValues={INITIAL_VALUES} onSubmit={handleSubmit}>
          {({ handleReset, isSubmitting, setFieldValue, values }) => {
            const isFieldDisabled = name => {
              if (isSubmitting) return true

              let populatedFields = Object.keys(values).filter(key => values[key] !== '')
              if (!populatedFields.length) return false

              if (
                name === 'participantCode' &&
                populatedFields.includes('managementNumber')
              ) {
                return false
              } else if (
                name === 'managementNumber' &&
                populatedFields.includes('participantCode')
              ) {
                return false
              } else if (!populatedFields.includes(name)) return true
            }

            const handleResetField = name => () => {
              setFieldValue(name, '')
            }

            return (
              <Form>
                <FormRow>
                  <div className={styles.flexWrapper}>
                    <Field
                      component={InlineFormField}
                      disabled={isFieldDisabled('participantCode')}
                      name='participantCode'
                      onReset={handleResetField('participantCode')}
                    />
                    <Field
                      component={InlineFormField}
                      disabled={isFieldDisabled('managementNumber')}
                      name='managementNumber'
                      onReset={handleResetField('managementNumber')}
                    />
                  </div>
                </FormRow>
                <Field
                  component={FormRowWithFormField}
                  disabled={isFieldDisabled('birthId')}
                  name='birthId'
                  onReset={handleResetField('birthId')}
                />
                <Field
                  component={FormRowWithFormField}
                  disabled={isFieldDisabled('ahbNumber')}
                  name='ahbNumber'
                  onReset={handleResetField('ahbNumber')}
                />
                <Field
                  component={FormRowWithFormField}
                  disabled={isFieldDisabled('animalKey')}
                  name='animalKey'
                  onReset={handleResetField('animalKey')}
                />
                <Field
                  component={FormRowWithFormField}
                  disabled={isFieldDisabled('bullCode')}
                  name='bullCode'
                  onReset={handleResetField('bullCode')}
                />
                <Field
                  component={FormRowWithFormField}
                  disabled={isFieldDisabled('sampleId')}
                  name='sampleId'
                  onReset={handleResetField('sampleId')}
                />
                <div className={styles.buttonWrapper}>
                  <a onClick={handleReset}>Reset</a>
                  <Button className='buttonPrimary' type='submit'>
                    Search
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </PaddedBlock>
    </div>
  ) : null
}

AnimalLookupForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default withRouter(AnimalLookupForm)
