import React from 'react'
import { render } from 'react-dom'
import initialiseExceptionMonitoring from '../config/exceptionMonitoring'
import axios from 'axios'

import createStore from './store/createStore'

import ApolloClientWrapper from 'components/ApolloClientWrapper'
import AuthHandler from 'components/AuthHandler'
import AppRoot from 'components/AppRoot'
import { Provider } from 'react-redux'

const store = createStore()
const renderApp = () =>
  render(
    <Provider store={store}>
      <AuthHandler />
      <ApolloClientWrapper>
        <AppRoot />
      </ApolloClientWrapper>
    </Provider>,
    document.querySelector('#root')
  )

const getAppConfig = () => {
  return axios.get('./config.json').then(response => response.data)
}

getAppConfig().then(config => {
  global.config = config
  initialiseExceptionMonitoring(config)
  renderApp()
})
